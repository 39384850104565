// Constants and Helper Functions

import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import { PiWavesBold, PiAngleBold } from 'react-icons/pi';
import { SlEnergy } from 'react-icons/sl';
import { TbLineHeight, TbArrowAutofitWidth, TbWaveSine } from 'react-icons/tb';
import { FaWind } from 'react-icons/fa';
import { RiInformation2Fill } from 'react-icons/ri';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { WiSunrise, WiSunset } from 'react-icons/wi';
import { TbSunrise, TbSunset } from "react-icons/tb";
import { FaArrowDown } from 'react-icons/fa6';

const COLOR = {
  waveEnergy: 'bg-yellow-500 dark:bg-yellow-600',
  waveHeight: 'bg-amber-500 dark:bg-amber-600',
  swellHeight: 'bg-orange-500/[.85] dark:bg-orange-600/[.85]',
  windChop: 'bg-sky-400/[.85] dark:bg-sky-600',
  barBackground: 'bg-zinc-200 dark:bg-zinc-700',
  tideHeight: 'bg-violet-400 dark:bg-violet-700',
  arrowColor: 'inherit',
  windGusts: 'bg-blue-400 dark:bg-blue-600',
  hover: 'bg-red-200/60 dark:bg-red-400/20',
  closestTime: 'bg-pink-200/70 dark:bg-pink-400/20',
  wavePeriod: 'bg-transparent'
};

const DataCell = React.memo(({
  value,
  index,
  color,
  maxValue,
  minValue = 0,
  unit = '',
  onClick,
  isNight,
  hoverIndex,
  closestTimeIndex,
  height = "h-12 sm:h-14 lg:h-16" // Default height
}) => {
  const range = maxValue - minValue;
  const normalizedValue = (value - minValue) / range;
  const cellHeight = `${Math.max(0, Math.min(normalizedValue, 1)) * 100}%`;
  const formattedValue = value === 0 ? '' : value ? Number(value.toFixed(2)).toString() : '-';
  const showValue = !isNight || index === hoverIndex;

  // Determine if both hovered and isNight
  const isHoveredNight = index === hoverIndex && isNight;

  return (
    <td
      className={`p-px ${isNight ? 'min-w-2.5 max-w-2.5' : 'min-w-9'} border-b border-zinc-300 dark:border-zinc-600 ${index === hoverIndex ? COLOR.hover : ''} ${index === closestTimeIndex ? COLOR.closestTime : ''}`}
      onClick={() => onClick(index)}
    >
      <div className={`relative ${height}`}>
        <div
          className={`absolute bottom-0 w-full ${color} text-center rounded-sm`}
          style={{ height: cellHeight }}
        />
        {showValue && (
          <span
            className={`absolute inset-0 flex items-center justify-center text-xs font-semibold text-zinc-800 dark:text-zinc-100 z-10`}
          >
            {isHoveredNight ? (
              <div className={`bg-zinc-100/65 dark:bg-zinc-700/65 py-2.5 px-0.5 rounded z-50`}>
                {formattedValue}{unit}
              </div>
            ) : (
              `${formattedValue}${unit}`
            )}
          </span>
        )}
      </div>
    </td>
  );
});

const ArrowCell = React.memo(({
  value, index, onClick, isNight, hoverIndex, closestTimeIndex
}) => (
  <td
    className={`py-px sm:py-1 ${isNight ? 'max-w-2.5' : 'min-w-9'} border-b border-zinc-300 dark:border-zinc-600 text-center ${index === hoverIndex ? COLOR.hover : ''} ${index === closestTimeIndex ? COLOR.closestTime : ''}`}
    onClick={() => onClick(index)}
    style={{ verticalAlign: 'middle' }}
  >
    {(!isNight || index == hoverIndex) &&  (
      <span
        className="inline-block"
        style={{
          transform: `rotate(${value}deg)`,
          color: COLOR.arrowColor,
          lineHeight: 1,
          verticalAlign: 'center'
        }}
      >
        <FaArrowDown size={13} />
      </span>
    )}
  </td>
));

const TableRow = React.memo(({
  label, icon, data, renderCell, showTooltips, tooltipText, onClick, isLastHourOfDay, surfInfoData, hoverIndex, closestTimeIndex
}) => (
  <tr>
    <td className="px-1 text-base text-center sticky left-0 z-20 bg-zinc-200 dark:bg-zinc-600 border-y-3 border-zinc-300 dark:border-zinc-600" style={{ left: '-1px' }}>
      <a className={`flex justify-center items-center ${label}`}>
        {icon}
      </a>
      <Tooltip anchorSelect={`.${label}`} place="right" isOpen={showTooltips}>
        {tooltipText}
      </Tooltip>
      <Tooltip anchorSelect={`.${label}`} place="right">
        {tooltipText}
      </Tooltip>
    </td>
    {data.map((value, index) => {
      const isNight = surfInfoData[index].isNight;
      return (
        <React.Fragment key={index}>
          {renderCell(value, index, isNight)}
          {isLastHourOfDay(index) && index < data.length - 1 && (
            <td className="w-0.5 bg-zinc-400/[.8] dark:bg-zinc-200/[.6]"></td>
          )}
        </React.Fragment>
      );
    })}
  </tr>
));

const TableHeader = React.memo(({
  labels, groupDates, onToggleAllTooltips, showAllTooltips, setShowHiddenRows, showHiddenRows, isLastHourOfDay, onCellClick, surfInfoData, hoverIndex, closestTimeIndex
}) => {
  // Helper function to format date labels
  const formatDateLabel = (dateStr, index) => {
    // Properly extract day and date parts by handling multiple spaces
    const cleanDateStr = dateStr.replace(/\s+/g, ' ');
    const parts = cleanDateStr.split(' ');
    const dayName = parts[0];
    const day = parts[1];
    const month = parts[2];

    if (index === 0) return `Yesterday ${day} ${month}`;
    if (index === 1) return `Today ${day} ${month}`;
    if (index === 2) return `Tomorrow ${day} ${month}`;
    return `${dayName} ${day} ${month}`;
  };

  return (
    <thead>
      <tr className="bg-zinc-100 dark:bg-zinc-700 text-zinc-800 dark:text-zinc-100">
        <th className="text-sm text-center sticky left-0 z-30 bg-zinc-200 dark:bg-zinc-600 border-b-3 border-zinc-300 dark:border-zinc-600" onClick={onToggleAllTooltips} style={{ left: '-1px' }}>
          <div className="text-lg flex justify-center items-center">
            <RiInformation2Fill />
          </div>
        </th>
        {Object.entries(groupDates(labels)).map(([date, { startIndex, endIndex }], idx) => (
          <th
            key={date}
            colSpan={endIndex - startIndex + 2}
            className="p-1 border-b border-zinc-300 dark:border-zinc-600"
          >   
            {formatDateLabel(date, idx)}
          </th>
        ))}
      </tr>
      <tr className="bg-zinc-100 dark:bg-zinc-700 text-zinc-800 dark:text-zinc-100">
        <th className="sticky left-0 z-20 bg-zinc-200 dark:bg-zinc-600 border-y-3 border-zinc-300 dark:border-zinc-600" style={{ left: '-1px' }}>
          <a className="appendSwell">
            <button
              className="mt-1 bg-zinc-100 dark:bg-zinc-700 py-1 px-2 rounded"
              onClick={() => setShowHiddenRows(!showHiddenRows)}
            >
              {showHiddenRows ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </button>
          </a>
        </th>

{labels.map((label, index) => {
  const isNight = surfInfoData[index].isNight;
  const currentDate = new Date(surfInfoData[index].date);
  const currentHour = currentDate.getUTCHours();
  const sunriseTime = surfInfoData[index].sunrise ? new Date(surfInfoData[index].sunrise) : null;
  const sunsetTime = surfInfoData[index].sunset ? new Date(surfInfoData[index].sunset) : null;
  const isSunriseHour = sunriseTime && currentHour === sunriseTime.getUTCHours();
  const isSunsetHour = sunsetTime && currentHour === sunsetTime.getUTCHours();

  const formatUTCTime = (date) => {
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  return (
    <React.Fragment key={index}>
      <th
        className={`pb-1 pt-0 border-b border-zinc-300 dark:border-zinc-600 ${
          isNight ? 'max-w-2.5' : 'min-w-9'
        } ${index === hoverIndex ? COLOR.hover : ''} ${
          index === closestTimeIndex ? COLOR.closestTime : ''
        }`}
        onClick={() => onCellClick(index)}
      >
        {(!isNight || index === hoverIndex) && (
          <div className={`flex flex-col justify-center relative w-full ${
            isNight ? 'max-w-2.5 right-1.5' : ''
          } `}>
            {isSunriseHour && (
              <div className="absolute bottom-7 left-1" style={{ marginLeft: `${sunriseTime.getUTCMinutes()*0.6}px` }}
                data-tooltip-id={`sunrise-${index}`}
              >
                <div className="bg-zinc-100 dark:bg-zinc-700 rounded-full">
                <TbSunrise size={25}  />
                </div>
                <Tooltip 
                  className="z-10 font-medium"
                  id={`sunrise-${index}`}
                  content={'Sunrise '+formatUTCTime(sunriseTime)}
                />
              </div>
            )}
            <span>{label.split(' ')[1]}</span>
            {isSunsetHour && (
             <div className="absolute bottom-7 left-1" style={{ marginLeft: `${sunsetTime.getUTCMinutes()*0.6}px` }}
                
                data-tooltip-id={`sunset-${index}`}
              > <div className="bg-zinc-100 dark:bg-zinc-700 rounded-full ">
                <TbSunset size={25} />
                </div>
                <Tooltip 
                  className="z-10 font-medium"
                  id={`sunset-${index}`}
                  content={'Sunset '+formatUTCTime(sunsetTime)}
                />
              </div>
            )}
          </div>
        )}
      </th>
      {isLastHourOfDay(index) && index < labels.length - 1 && (
        <th className="w-0.5 bg-zinc-400/[.8] dark:bg-zinc-200/[.6]"></th>
      )}
    </React.Fragment>
  );
})}
    </tr>
  </thead>
  );
});

const SurfInfoTable = ({
  data,
  surfInfoData,
  tideData = [],
  labels,
  waveEnergies,
  groupDates,
  isLastHourOfDay,
  hoverIndex,
  onCellClick,
  showAllTooltips,
  setShowAllTooltips,
  closestTimeIndex,
  windData,
  isSurfOverviewInteracting
}) => {
  const [showHiddenRows, setShowHiddenRows] = useState(false);
  const [isMouseOverTable, setIsMouseOverTable] = useState(false);
  const tableContainerRef = useRef(null);
  
  // Add state for drag scrolling (desktop only)
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleClick = useCallback((index) => {
    if (onCellClick) {
      onCellClick(index, false);
    }
  }, [onCellClick]);

  // Scroll event handler
  const scrollEndTimerRef = useRef(null);

  const handleScroll = useCallback(() => {
    // First set isMouseOverTable to true
    setIsMouseOverTable(true);
  
    // Clear any existing timer
    if (scrollEndTimerRef.current) {
      clearTimeout(scrollEndTimerRef.current);
    }
  
    // Set a new timer to reset isMouseOverTable once scrolling ends
    scrollEndTimerRef.current = setTimeout(() => {
      setIsMouseOverTable(false);
    }, 300);
  
    if (tableContainerRef.current) {
      const scrollLeft = tableContainerRef.current.scrollLeft;
      const containerWidth = tableContainerRef.current.clientWidth;
      const tableWidth = tableContainerRef.current.scrollWidth;
      const numberOfColumns = labels.length;
      const cellWidth = tableWidth / numberOfColumns;
      const centeredIndex = Math.round((scrollLeft + containerWidth / 2) / cellWidth) - 1;
      if (!isSurfOverviewInteracting) {
        handleClick(centeredIndex);
      }
    }
  }, [handleClick, labels.length, isSurfOverviewInteracting]);
  
  // Mouse down handler for drag scrolling (desktop only)
  const handleMouseDown = useCallback((e) => {
    // Skip if this is a touch event (mobile)
    if (e.touches) return;
    
    if (tableContainerRef.current) {
      setIsDragging(true);
      setStartX(e.pageX - tableContainerRef.current.offsetLeft);
      setScrollLeft(tableContainerRef.current.scrollLeft);
      tableContainerRef.current.style.cursor = 'grabbing';
      tableContainerRef.current.style.userSelect = 'none';
    }
  }, []);

  // Mouse move handler for drag scrolling (desktop only)
  const handleMouseMove = useCallback((e) => {
    if (!isDragging) return;
    e.preventDefault();
    if (tableContainerRef.current) {
      const x = e.pageX - tableContainerRef.current.offsetLeft;
      const walk = (x - startX) * 2; // Scroll speed multiplier
      tableContainerRef.current.scrollLeft = scrollLeft - walk;
    }
  }, [isDragging, startX, scrollLeft]);

  // Mouse up handler to end drag scrolling (desktop only)
  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    if (tableContainerRef.current) {
      tableContainerRef.current.style.cursor = 'grab';
      tableContainerRef.current.style.removeProperty('user-select');
    }
  }, []);

  useEffect(() => {
    const table = tableContainerRef.current;
    if (table) {
      // Add original event listeners
      table.addEventListener('scroll', handleScroll);
      table.addEventListener('mouseenter', () => setIsMouseOverTable(true));
      table.addEventListener('mouseleave', () => setIsMouseOverTable(false));
      
      // Add drag scroll event listeners (desktop only)
      table.addEventListener('mousedown', handleMouseDown);
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      
      // Set initial grab cursor for desktop
      table.style.cursor = 'grab';
      
      return () => {
        // Remove all event listeners on cleanup
        table.removeEventListener('scroll', handleScroll);
        table.removeEventListener('mouseenter', () => setIsMouseOverTable(true));
        table.removeEventListener('mouseleave', () => setIsMouseOverTable(false));
        table.removeEventListener('mousedown', handleMouseDown);
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [
    handleScroll, 
    handleMouseDown, 
    handleMouseMove, 
    handleMouseUp
  ]);

  const onToggleAllTooltips = useCallback(() => {
    setShowAllTooltips(!showAllTooltips);
  }, [showAllTooltips, setShowAllTooltips]);

  const renderDataCell = useCallback((value, index, color, maxValue, minValue = 0, unit = '', height) => {
    const isNight = surfInfoData[index].isNight;
    return (
      <DataCell
        value={value}
        index={index}
        color={color}
        maxValue={maxValue}
        minValue={minValue}
        unit={unit}
        onClick={handleClick}
        isNight={isNight}
        hoverIndex={hoverIndex}
        closestTimeIndex={closestTimeIndex}
        height={height} // Pass the height prop
      />
    );
  }, [surfInfoData, handleClick, hoverIndex, closestTimeIndex]);

  const renderArrowCell = useCallback((value, index) => {
    const isNight = surfInfoData[index].isNight;
    return (
      <ArrowCell
        value={value}
        index={index}
        onClick={handleClick}
        isNight={isNight}
        hoverIndex={hoverIndex}
        closestTimeIndex={closestTimeIndex}
      />
    );
  }, [surfInfoData, handleClick, hoverIndex, closestTimeIndex]);

  const isLastHour = useCallback((index) => isLastHourOfDay(data.hourly.time[index]), [data, isLastHourOfDay]);

  return (
    <div className="shadow-sm rounded-sm">
      <div
        ref={tableContainerRef}
        onScroll={handleScroll}
        className="overflow-x-auto rounded-sm will-change-transform"
      >
        <table className="bg-zinc-100 dark:bg-zinc-700 border-y border-zinc-300 dark:border-zinc-600 rounded-lg">
          <TableHeader
            labels={labels}
            groupDates={groupDates}
            onToggleAllTooltips={onToggleAllTooltips}
            showAllTooltips={showAllTooltips}
            setShowHiddenRows={setShowHiddenRows}
            showHiddenRows={showHiddenRows}
            isLastHourOfDay={isLastHour}
            onCellClick={handleClick}
            surfInfoData={surfInfoData}
            hoverIndex={hoverIndex}
            closestTimeIndex={closestTimeIndex}
          />
          <tbody>
            <TableRow
              label="waveE"
              icon={<><PiWavesBold /><SlEnergy /></>}
              data={waveEnergies}
              renderCell={(value, index, isNight) => renderDataCell(value, index, COLOR.waveEnergy, 2000)}
              showTooltips={showAllTooltips}
              tooltipText="Wave Energy (kJ:m²)"
              onClick={handleClick}
              isLastHourOfDay={isLastHour}
              surfInfoData={surfInfoData}
              hoverIndex={hoverIndex}
              closestTimeIndex={closestTimeIndex}
            />
            <TableRow
              label="waveHeight"
              icon={<><PiWavesBold /><TbLineHeight /></>}
              data={data.hourly.wave_height}
              renderCell={(value, index, isNight) => renderDataCell(value, index, COLOR.waveHeight, 3)}
              showTooltips={showAllTooltips}
              tooltipText="Wave Height (meters)"
              onClick={handleClick}
              isLastHourOfDay={isLastHour}
              surfInfoData={surfInfoData}
              hoverIndex={hoverIndex}
              closestTimeIndex={closestTimeIndex}
            />
            <TableRow
              label="wavePeriod"
              icon={<><PiWavesBold /><TbArrowAutofitWidth /></>}
              data={data.hourly.wave_period}
              renderCell={(value, index, isNight) => renderDataCell(value, index, COLOR.wavePeriod, 20, 0, '', 'h-8')}
              showTooltips={showAllTooltips}
              tooltipText="Wave Period (seconds)"
              onClick={handleClick}
              isLastHourOfDay={isLastHour}
              surfInfoData={surfInfoData}
              hoverIndex={hoverIndex}
              closestTimeIndex={closestTimeIndex}
            />
            <TableRow
              label="waveDir"
              icon={<><PiWavesBold /><PiAngleBold /></>}
              data={data.hourly.wave_direction}
              renderCell={(value, index, isNight) => renderArrowCell(value, index)}
              showTooltips={showAllTooltips}
              tooltipText="Wave Direction"
              onClick={handleClick}
              isLastHourOfDay={isLastHour}
              surfInfoData={surfInfoData}
              hoverIndex={hoverIndex}
              closestTimeIndex={closestTimeIndex}
            />
            {showHiddenRows && (
              <>
                <TableRow
                  label="swellHeight"
                  icon={<><PiWavesBold /><TbLineHeight /></>}
                  data={data.hourly.swell_wave_height}
                  renderCell={(value, index, isNight) => renderDataCell(value, index, COLOR.swellHeight, 3)}
                  showTooltips={showAllTooltips}
                  tooltipText="Swell Height (meters)"
                  onClick={handleClick}
                  isLastHourOfDay={isLastHour}
                  surfInfoData={surfInfoData}
                  hoverIndex={hoverIndex}
                  closestTimeIndex={closestTimeIndex}
                />
                <TableRow
                  label="swellPeriod"
                  icon={<><PiWavesBold /><TbArrowAutofitWidth /></>}
                  data={data.hourly.swell_wave_period}
                  renderCell={(value, index, isNight) => renderDataCell(value, index, COLOR.wavePeriod, 20, 0, '', 'h-8')}
                  showTooltips={showAllTooltips}
                  tooltipText="Swell Period (seconds)"
                  onClick={handleClick}
                  isLastHourOfDay={isLastHour}
                  surfInfoData={surfInfoData}
                  hoverIndex={hoverIndex}
                  closestTimeIndex={closestTimeIndex}
                />
                <TableRow
                  label="swellDir"
                  icon={<><PiWavesBold /><PiAngleBold /></>}
                  data={data.hourly.swell_wave_direction}
                  renderCell={(value, index, isNight) => renderArrowCell(value, index)}
                  showTooltips={showAllTooltips}
                  tooltipText="Swell Direction"
                  onClick={handleClick}
                  isLastHourOfDay={isLastHour}
                  surfInfoData={surfInfoData}
                  hoverIndex={hoverIndex}
                  closestTimeIndex={closestTimeIndex}
                />
                <TableRow
                  label="windChopHeight"
                  icon={<><FaWind className="text-sm"/><TbLineHeight /></>}
                  data={data.hourly.wind_wave_height}
                  renderCell={(value, index, isNight) => renderDataCell(value, index, COLOR.windChop, 3)}
                  showTooltips={showAllTooltips}
                  tooltipText="Wind Chop Height (meters)"
                  onClick={handleClick}
                  isLastHourOfDay={isLastHour}
                  surfInfoData={surfInfoData}
                  hoverIndex={hoverIndex}
                  closestTimeIndex={closestTimeIndex}
                />
                <TableRow
                  label="windChopDir"
                  icon={<><FaWind className="text-sm"/><PiAngleBold /></>}
                  data={data.hourly.wind_wave_direction}
                  renderCell={(value, index, isNight) => value !== 0 ? renderArrowCell(value, index) : <td className={`text-center border-b border-zinc-300 dark:border-zinc-600 ${index === hoverIndex ? COLOR.hover : ''}`} onClick={() => handleClick(index)}></td>}
                  showTooltips={showAllTooltips}
                  tooltipText="Wind Chop Direction"
                  onClick={handleClick}
                  isLastHourOfDay={isLastHour}
                  surfInfoData={surfInfoData}
                  hoverIndex={hoverIndex}
                  closestTimeIndex={closestTimeIndex}
                />
              </>
            )}
            <TableRow
              label="windGusts"
              icon={<><FaWind className="text-sm" /><SlEnergy /></>}
              data={windData.hourly.wind_gusts_10m}
              renderCell={(value, index, isNight) => renderDataCell(value, index, COLOR.windGusts, 80)}
              showTooltips={showAllTooltips}
              tooltipText="Wind Gusts (km/h)"
              onClick={handleClick}
              isLastHourOfDay={isLastHour}
              surfInfoData={surfInfoData}
              hoverIndex={hoverIndex}
              closestTimeIndex={closestTimeIndex}
            />
            <TableRow
              label="windDir"
              icon={<><FaWind className="text-sm" /><PiAngleBold /></>}
              data={windData.hourly.wind_direction_10m}
              renderCell={(value, index, isNight) => renderArrowCell(value, index)}
              showTooltips={showAllTooltips}
              tooltipText="Wind Direction"
              onClick={handleClick}
              isLastHourOfDay={isLastHour}
              surfInfoData={surfInfoData}
              hoverIndex={hoverIndex}
              closestTimeIndex={closestTimeIndex}
            />
            <TableRow
              label="tideHeight"
              icon={<><TbWaveSine /><TbLineHeight /></>}
              data={tideData ? tideData.map(t => t.height) : []}
              renderCell={(value, index, isNight) => renderDataCell(value, index, COLOR.tideHeight, 3, -3)}
              showTooltips={showAllTooltips}
              tooltipText="Tide Height (meters)"
              onClick={handleClick}
              isLastHourOfDay={isLastHour}
              surfInfoData={surfInfoData}
              hoverIndex={hoverIndex}
              closestTimeIndex={closestTimeIndex}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SurfInfoTable;