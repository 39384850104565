import React, { useState, useEffect, useRef, useCallback } from 'react';
import MapComponent from './components/Map';
import SearchBar from './components/SearchBar';
import Header from './components/Header';
import SurfInfo from './components/SurfInfo';
import SpotInfo from './components/SpotInfo';
import { about } from './about';

function App({ initialData, initialTideData, windData, latitude, longitude, onLocationChange, onSearch, locationName }) {
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [darkMode, setDarkMode] = useState(() => {
    if (typeof window !== 'undefined') {
      const storedDarkMode = localStorage.getItem('darkMode');
      return storedDarkMode !== null ? JSON.parse(storedDarkMode) : window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
    return false;
  });
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [hoveredLocation, setHoveredLocation] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [surfData, setSurfData] = useState(initialData?.surfData || []);
  const [tideData, setTideData] = useState(initialTideData || null);
  const [windDataState, setWindData] = useState(windData || []); // Add windData to state
  const [showInfo, setShowInfo] = useState(false);
  const tableRef = useRef(null);
  const [isClient, setIsClient] = useState(false);
  const [, setWaveEnergies] = useState([]);
  const [isSurfOverviewInteracting, setIsSurfOverviewInteracting] = useState(false);

  useEffect(() => {
    setIsClient(true);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    if (darkMode) {
      document.documentElement.classList.add('dark');
      metaThemeColor.setAttribute("content", "#18181b");
    } else {
      document.documentElement.classList.remove('dark');
      metaThemeColor.setAttribute("content", "#ffffff");
    }
  }, [darkMode]);

  useEffect(() => {
    setTideData(initialTideData || null);
  }, [initialTideData]);

  useEffect(() => {
    setSurfData(initialData?.surfData || []);
  }, [initialData]);

  useEffect(() => {
    setWindData(windData || []); // Update windData state when prop changes
  }, [windData]);

  const handleDataLoaded = useCallback((surfInfoData) => {
    setWaveEnergies(surfInfoData);
    setSurfData(surfInfoData);
  }, []);

  useEffect(() => {
    if (isClient) {
      const storedDarkMode = localStorage.getItem('darkMode');
      if (storedDarkMode !== null) {
        setDarkMode(JSON.parse(storedDarkMode));
      } else {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        setDarkMode(mediaQuery.matches);

        const handleChange = (e) => setDarkMode(e.matches);
        mediaQuery.addEventListener('change', handleChange);

        return () => {
          mediaQuery.removeEventListener('change', handleChange);
        };
      }
    }
  }, [isClient]);

  useEffect(() => {
    if (isClient && tableRef.current && hoverIndex !== null && isSurfOverviewInteracting) {
      const delayScroll = setTimeout(() => {
        const cell = tableRef.current.querySelector(`td:nth-child(${hoverIndex + 5})`);
        if (cell) {
          cell.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
          });
        }
      }, 1); // 1ms delay (adjust as needed)
  
      return () => clearTimeout(delayScroll); // Cleanup the timeout when component unmounts or dependencies change
    }
  }, [hoverIndex, isClient, isSurfOverviewInteracting]);

  useEffect(() => {
    if (isClient) {
      const handlePopState = () => {
        const [lat, lon] = extractCoordinatesFromUrl(window.location.href);
        if (lat && lon) {
          onLocationChange(lat, lon);
        }
      };

      window.addEventListener('popstate', handlePopState);

      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [onLocationChange, isClient]);

  const handleSearch = useCallback((location) => {
    if (location && location.latitude && location.longitude) {
      setSearchQuery(location.text || '');
      setSelectedLocation({
        latitude: parseFloat(location.latitude),
        longitude: parseFloat(location.longitude),
        text: location.text || 'Selected Location'
      });
      onSearch(location);
    } else {
      console.error("Invalid location selected:", location);
    }
  }, [onSearch]);

  const handleMapClick = useCallback((longitude, latitude) => {
    setSearchQuery(`Custom Spot ${latitude.toFixed(2)} / ${longitude.toFixed(2)}`);
    setSelectedLocation({
      latitude,
      longitude,
      text: 'Custom Spot'
    });
    onLocationChange(latitude, longitude);
  }, [onLocationChange]);

  const handleSpotClick = useCallback((spot) => {
    const latitude = parseFloat(spot.latitude);
    const longitude = parseFloat(spot.longitude);
  
    if (isNaN(latitude) || isNaN(longitude)) {
      console.error('Invalid latitude or longitude:', spot);
      return;
    }
  
    setSearchQuery(spot.name || `Spot ${latitude.toFixed(2)} / ${longitude.toFixed(2)}`);
    setSelectedLocation({
      latitude: latitude,
      longitude: longitude,
      text: spot.text || 'Selected Spot'
    });
    onLocationChange(latitude, longitude);
  }, [onLocationChange]);

  const toggleDarkMode = useCallback(() => {
    setDarkMode(prevMode => {
      const newMode = !prevMode;
      localStorage.setItem('darkMode', JSON.stringify(newMode));
      if (newMode) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
      return newMode;
    });
  }, []);

  const toggleInfo = useCallback(() => {
    setShowInfo(prev => !prev);
  }, []);

  const handleCellClick = useCallback((index, fromSurfOverview = false) => {
    setHoverIndex(index);
    setIsSurfOverviewInteracting(fromSurfOverview);
  }, []);

  const handleLocateUser = useCallback(() => {
    if (navigator.geolocation) {
      setSearchQuery('Getting your location...');
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          setSearchQuery(`Current Location ${lat.toFixed(2)} / ${lon.toFixed(2)}`);
          setSelectedLocation({
            latitude: lat,
            longitude: lon,
            text: 'Current Location'
          });
          onLocationChange(lat, lon);
        },
        (error) => {
          console.error('Geolocation error:', error);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              setSearchQuery('Location access denied');
              break;
            case error.POSITION_UNAVAILABLE:
              setSearchQuery('Location unavailable');
              break;
            case error.TIMEOUT:
              setSearchQuery('Location request timed out');
              break;
            default:
              setSearchQuery('Error getting location');
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        }
      );
    } else {
      setSearchQuery('Location not supported');
    }
  }, [onLocationChange]);


  if (!isClient) {
    return null;
  }

  if (isLoading) {
    return (
     <div className={`App h-dvh py-4 w-screen flex flex-col ${darkMode ? 'bg-zinc-900 text-white' : 'bg-white text-black'}`}>
        <Header toggleDarkMode={toggleDarkMode} darkMode={darkMode} toggleInfo={toggleInfo} onLocateUser={handleLocateUser}/>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <div className={`App h-dvh w-screen flex flex-col ${darkMode ? 'bg-zinc-900 text-white' : 'bg-white text-black'}`}>
      <div className="w-full px-4 lg:px-6 xl:px-8 flex-grow flex flex-col">
        <Header toggleDarkMode={toggleDarkMode} darkMode={darkMode} toggleInfo={toggleInfo} onLocateUser={handleLocateUser} />
        {showInfo && (
         <div className="bg-zinc-100 dark:bg-zinc-800 p-4 mb-4 rounded-sm">
          <div className="max-w-screen-lg mx-auto">
          <h1 className="text-xl font-semibold mb-4">About OceanOrb.org &nbsp; &nbsp; &nbsp; - &nbsp; &nbsp; &nbsp; Contact info@oceanorb.org</h1>
        
        <b className="block mb-2">{about.title1}</b>
        <p className="mb-4">{about.paragraph1}</p>
      
        <b className="block mb-2">{about.title2}</b>
        <p className="mb-4">{about.paragraph2}</p>
      
        <b className="block mb-2">{about.title3}</b>
        <p className="mb-4">{about.paragraph3}</p>
      
        <b className="block mb-2">{about.title4}</b>
        <p className="mb-4">{about.paragraph4}</p>
      
        <b className="block mb-2">{about.title5}</b>
        <p className="mb-6">{about.paragraph5}</p>
      
        <b className="block mb-2">{about.sources}</b>
        <ul className="mb-6">
          {about.dataSources.map((source, index) => (
            <li key={index} className="mb-1">{source}</li>
          ))}
        </ul>
      
        <b className="block mb-2">{about.legal1}</b>
        <b className="block mb-2">{about.legal2}</b>
        <p className="mb-4">{about.legal3}</p>
      
        <b className="block mb-2">{about.legal4}</b>
        <p className="mb-4">{about.legal5}</p>
      
        <b className="block mb-2">{about.legal6}</b>
        <p className="mb-4">{about.legal7}</p>
      
        <b className="block mb-2">{about.legal8}</b>
        <p className="mb-4">{about.legal9}</p>
      
        <b className="block mb-2">{about.legal10}</b>
        <p className="mb-4">{about.legal11}</p>
      
        <b className="block mb-2">{about.legal12}</b>
        <p>{about.legal13}</p>
   
          </div>
         </div>
        )}
        <SearchBar
          onSearch={handleSearch}
          setHoveredLocation={setHoveredLocation}
          searchQuery={searchQuery}
        />
     
     <SpotInfo
      selectedLocation={selectedLocation}
      locationName={locationName}
      latitude={latitude}
      longitude={longitude}
      timezone={initialData?.timezone}
      utcOffset={initialData?.utc_offset_seconds}
      />

        <div className="flex-grow flex">
          <MapComponent 
            location={hoveredLocation || selectedLocation || (longitude && latitude ? { longitude, latitude } : null)} 
            isDarkMode={darkMode}
            onMapClick={handleMapClick}
            surfData={surfData}
            windData={windDataState} // Pass windData to MapComponent
            hoverIndex={hoverIndex}
            onSpotClick={handleSpotClick}
          />
        </div>
        {((selectedLocation && selectedLocation.latitude !== 0 && selectedLocation.longitude !== 0) || (latitude && longitude)) && (
          <div>
            <div className="overflow-x-auto will-change-transform" ref={tableRef}>
              <SurfInfo
                latitude={selectedLocation?.latitude || latitude}
                longitude={selectedLocation?.longitude || longitude}
                onDataLoaded={handleDataLoaded}
                hoverIndex={hoverIndex}
                onCellClick={handleCellClick}
                initialData={initialData}
                initialTideData={tideData}
                windData={windDataState} // Pass windData to SurfInfo
                isServer={false}
                isSurfOverviewInteracting={isSurfOverviewInteracting}
              />
              
            </div>        
          </div>  
        )}
        <div className='h-1.5'></div>
      </div>
    </div>
  );
}

export default App;